import NextAuth from 'next-auth';
import CredentialsProvider from 'next-auth/providers/credentials';

import { UsersCacheKey } from '@/lib/types/api/cache-keys';
import { ProjectForNextAuthSession } from '@/lib/types/global';

import { revalidateCache } from '@/lib/utils/functions/cache/revalidate-cache';
import { toNextAuthProjectSelected } from '@/lib/utils/functions/mappers/next-auth/to-next-auth-project-selected';
import { toPreviousSelectedProject } from '@/lib/utils/functions/mappers/next-auth/to-previous-selected-project';

import { RolePermissionsName } from '@/lib/services/roles/permissions';
import { User } from '@/lib/services/user/user';

import { setCookie } from './src/lib/actions/cookies';

const BASEDURATIONINSECONDS = 28_000; // 8 horas

export const { auth, handlers, signIn, signOut } = NextAuth({
  callbacks: {
    // Callback de JWT personalizado para manejar la lógica de tokens.
    jwt: async (payload) => {
      // Actualiza el token con información del usuario si está disponible.
      if (payload.user) {
        // La información básica del usuario se copia directamente al token.
        payload.token.id = payload.user.id as string;
        payload.token.name = payload.user.name as string;
        payload.token.access_token = payload.user.token as string;
        payload.token.permissions = payload.user.permissions as RolePermissionsName[];
        payload.token.roles = payload.user.roles as string[];
        payload.token.selectedProject = toNextAuthProjectSelected(payload.user.selectedProject);
        payload.token.picture = payload.user.image as string;
        payload.token.hasBasicPassword = payload?.user?.hasBasicPassword as boolean;
        payload.token.expiresAt = new Date(Date.now() + BASEDURATIONINSECONDS * 1000).toISOString();
      }

      const tokenHasExpiresAt =
        payload.token.expiresAt && typeof payload?.token?.expiresAt == 'string' ? new Date(payload.token.expiresAt as string) : null;

      if (tokenHasExpiresAt && tokenHasExpiresAt < new Date()) {
        return null;
      }

      // Actualiza el token solo si el trigger es 'update' y solo se solicita actualizar el token.
      if (payload.trigger === 'update' && payload?.session?.updateTokenOnly) {
        return {
          ...payload.token,
          access_token: payload?.session?.newToken as string,
        };
      }

      // Lógica para actualizar la información del proyecto seleccionado en el token.
      if (payload.trigger === 'update') {
        if (payload.session?.selectedProject) {
          payload.token.previousSelectedProject = toPreviousSelectedProject(payload.token.selectedProject);
          payload.token.selectedProject = toNextAuthProjectSelected(payload.session.selectedProject);
        }

        if (payload.session?.previousSelectedProject) {
          payload.token.previousSelectedProject = toPreviousSelectedProject(payload.session.previousSelectedProject);
        }
      }

      // Fuerza la revalidación del cache y actualiza la información del usuario en el token si es necesario.
      if (payload.session?.force === true) {
        await revalidateCache(UsersCacheKey.CURRENT);

        const user = await User.UserInfo();

        // Si se recibe información del usuario, actualiza el token con estos datos.
        if (user.data) {
          // Actualiza el token con los detalles del usuario.
          // Esto incluye información como el ID, nombre, permisos, roles, etc.
          payload.token.id = user.data.id as string;
          payload.token.name = user.data.fullName as string;
          payload.token.permissions = user.data.permissions as RolePermissionsName[];
          payload.token.roles = user.data.roles.map((r) => r.name);
          payload.token.picture = user.data.profilePicture as string;
          payload.token.hasBasicPassword = user?.data?.hasBasicPassword as boolean;

          // Intenta actualizar el proyecto seleccionado en el token si este ha cambiado.
          const currentSelectedProject = payload.token.selectedProject as ProjectForNextAuthSession;
          const selectedProjectUpdated = user.data.assignedProjects.find((p) => p.id === currentSelectedProject?.id);
          if (selectedProjectUpdated) {
            payload.token.selectedProject = toNextAuthProjectSelected(selectedProjectUpdated);
          }
        }

        // Si no se encuentra el usuario, devuelve null para indicar un fallo en la autenticación.
        if (!user) {
          return null;
        }
      }

      // Devuelve el token actualizado.
      return payload.token;
    },

    // Callback de sesión para personalizar el objeto de sesión que se devuelve al cliente.
    session: ({ session, token }) => {
      // Incluye el access_token en la sesión para su uso en el cliente.
      session.access_token = token.access_token as string;

      // Actualiza la sesión con detalles específicos del usuario.
      // Estos detalles están basados en el token JWT actualizado anteriormente.
      session.user.id = token.id as string;
      session.user.permissions = token.permissions as RolePermissionsName[];
      session.user.roles = token.roles as string[];
      session.user.name = token.name as string;
      session.user.selectedProject = toNextAuthProjectSelected(token.selectedProject);
      session.user.image = token.picture as string;
      session.user.hasBasicPassword = token?.hasBasicPassword as boolean;
      session.user.previousSelectedProject = toPreviousSelectedProject(token?.previousSelectedProject);
      return session;
    },
  },
  pages: {
    signIn: '/auth', // Ruta personalizada para la página de inicio de sesión.
  },
  providers: [
    CredentialsProvider({
      async authorize(credentials) {
        if (!credentials) return null;

        // Intenta autenticar al usuario con sus credenciales.
        const response = await User.Authenticate({
          email: credentials.email as string,
          password: credentials.password as string,
        });

        // Maneja errores de autenticación estableciendo una cookie con el mensaje de error.
        if (!response?.succeeded) {
          setCookie({
            cookie: 'authError',
            path: '/',
            value: response?.message as string,
          });
        }

        // Si la autenticación falla o no hay datos, devuelve null.
        if (!response?.data) return null;

        // Si la autenticación es exitosa, devuelve el objeto de usuario para ser utilizado en el token.
        return {
          // Detalles del usuario autenticado que se incluirán en el token.
          // Esto incluye información como proyectos asignados, email, género, etc.
          email: response.data.email,
          hasBasicPassword: response?.data?.hasBasicPassword,
          id: response.data.id,
          image: response.data.profilePicture,
          name: response.data.fullName,
          permissions: response.data.permissions,
          roles: response.data.roles,
          selectedProject: null,
          token: response.data.token, // El token JWT específico del usuario.
        };
      },
      credentials: {
        email: { label: 'Correo electrónico', type: 'email' },
        password: { label: 'Contraseña', type: 'password' },
      },
      id: 'credentials', // Identificador único para el proveedor de credenciales.
      name: 'Credentials', // Nombre descriptivo para el proveedor de credenciales.
    }),
  ],
  secret: process.env.NEXTAUTH_SECRET,
  session: {
    maxAge: BASEDURATIONINSECONDS, // 8 horas
    strategy: 'jwt',
  },
});
