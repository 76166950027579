export interface ApiResponse<T extends Object> {
  data?: T;
  errors?: string[];
  message?: string;
  succeeded: boolean;
}

export interface ApiResponseWithPagination<T extends Object> extends ApiResponse<{ items: T } & ApiPaginationData> {}

export interface ApiPaginationOptions {
  all?: boolean;
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  sortColumn?: string;
  sortOrder?: 'asc' | 'desc';
}

export interface ApiPaginationData {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  page: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export enum HttpMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE',
}

export enum ContentType {
  FORM_URLENCODED = 'application/x-www-form-urlencoded',
  GIF = 'image/gif',
  HTML = 'text/html',
  JPEG = 'image/jpeg',
  JSON = 'application/json',
  MULTIPART_FORM_DATA = 'multipart/form-data',
  PLAIN_TEXT = 'text/plain',
  PNG = 'image/png',
  XML = 'application/xml',
}

export const PaginationDefaults = {
  page: 1,
  pageIndex: 1,
  pageSize: 10,
  sortOrder: 'desc' as const,
};

export interface ItemSelectList {
  id: number;
  name: string;
}

export interface ApiRequestSelectList<T = ItemSelectList[]> extends ApiResponse<T extends object ? T : never> {}

export type Optional<T, K extends keyof T> = Omit<T, K> & Pick<Partial<T>, K>;

export enum ProductionSource {
  ActivityChecks = 2,
  IncomeTracks = 1,
  ProductionReviews = 0,
}

export const productionSourceMap = [
  { description: '', label: 'Revisiones de producción', value: ProductionSource.ProductionReviews },
  { description: '', label: 'Hojas de chequeo', value: ProductionSource.ActivityChecks },
  { description: '', label: 'Estimaciones', value: ProductionSource.IncomeTracks },
];
