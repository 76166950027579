import { ProjectForNextAuthSession } from '@/lib/types/global';

export const toNextAuthProjectSelected = (data: any): ProjectForNextAuthSession => {
  return {
    currency: data?.currency,
    currencySymbol: data?.currencySymbol,
    directCostIndex: data?.directCostIndex,
    expectedCostAmount: data?.expectedCostAmount,
    expectedDirectCostAmount: data?.expectedDirectCostAmount,
    expectedIndirectCostAmount: data?.expectedIndirectCostAmount,
    expectedUtilityAmount: data?.expectedUtilityAmount,
    id: data?.id,
    indirectCostIndex: data?.indirectCostIndex,
    minorToolPerformance: data?.minorToolPerformance,
    name: data?.name,
    sellPrice: data?.sellPrice,
    standardCostIndex: data?.standardCostIndex,
    startDate: data?.startDate,
    status: data?.status,
    utilityIndex: data?.utilityIndex,
  };
};
