import { redirect } from 'next/navigation';

interface IRedirect {
  path: string;
}

export const nextRedirect = (options: IRedirect) => {
  const { path } = options;

  if (typeof window !== 'undefined') {
    const origin = window.location.origin;
    window.location.href = `${origin}${path}`;
    return;
  }

  redirect(path);
};
